import request from '../../services/request'

export const confirmBankAuthentication = (consentId) => {
  return request('post', `companies/confirm_bank_authentication`, {consent_id: consentId})
}

export const fetchBankAccounts = () => {
  return request('get', 'accounts/bank_and_tax')
}

export const fetchCurrentEmployee = () => {
  return request('get', 'employees/current')
}

export const fetchFinancialOverview = () => {
  return request('get', 'voucher_statistics/financial_overview')
}

export const fetchTransactions = (accountNumber, page, filterOnMissingAttachment) => {
  let url = `vouchers?account_number=${accountNumber}&page=${page}`

  if (filterOnMissingAttachment) {
    url += '&missing_attachment=true'
  }

  return request('get', url)
}

export const removeAttachment = (voucherId, attachmentId) => {
  return request('put', `vouchers/${voucherId}/remove_attachment`, {
    attachment_id: attachmentId
  })
}

export const fetchKeyFiguresSummary = () => {
  return request('get', 'voucher_statistics/key_figures')
}

export const fetchIncomeVsExpenditure = (from, to) => {
  let url = `voucher_statistics/income_vs_expenditure`
  if (from && to) {
    url += `?from=${from}&to=${to}`
  }

  return request('get', url)
}

export const fetchResult = (from, to) => {
  let url = `voucher_statistics/result`
  if (from && to) {
    url += `?from=${from}&to=${to}`
  }

  return request('get', url)
}

export const fetchHighestExpenditures = (from, to) => {
  let url = `voucher_statistics/highest_expenditures`
  if (from && to) {
    url += `?from=${from}&to=${to}`
  }

  return request('get', url)
}


export const initiateBankAuthentication = (method) => {
  return request('post', 'companies/initiate_bank_authentication', {method})
}

export const initiateKyc = () => {
  return request('post', 'companies/initiate_kyc', {})
}

export const confirmKyc = () => {
  return request('post', 'companies/confirm_kyc', {})
}
