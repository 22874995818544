import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function CardLineChart(props) {
  const defaultOptions = {
    maintainAspectRatio: false,
    responsive: true,
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target
      target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    scales: {
      x: {
        ticks: {
          color: "rgba(255,255,255,.7)",
        },
        display: true,
        title: {
          display: false,
          text: "Month",
          color: "white",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "rgba(255,255,255,.7)",
        },
        display: true,
        title: {
          display: false,
          text: "Value",
          color: "white",
        },
        grid: {
          display: true,
          color: "rgba(255, 255, 255, 0.15)",
          drawTicks: false,
          borderDash: [6, 3],
        },
      },
    },
  }

  const config = {
    type: "line",
    data: props.data,
    options: {...defaultOptions, ...props.options}
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-b bg-slate-700">
      {props.title && props.subTitle &&
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center text-left">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-slate-400 mb-1 text-xs font-semibold">
                {props.title}
              </h6>
              <h2 className="text-white text-xl font-semibold">
                {props.subTitle}
              </h2>
            </div>
          </div>
        </div>
      }
      <div className="p-4 flex-auto">
        {/* Chart */}
        <div className="relative h-350-px">
          <Line
            options={config.options}
            data={config.data}
          />
        </div>
      </div>
    </div>
  )
}
