import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  fetchBankAccounts,
  fetchFinancialOverview,
  fetchTransactions,
  fetchKeyFiguresSummary,
  removeAttachment,
  fetchIncomeVsExpenditure,
  fetchResult,
  fetchHighestExpenditures,
} from './dashboardAPI'

export const intialDashboardState = {
  economy: {
    status: 'idle',
    data: {
      income: [],
      expenditure: [],
      result: [],
      highest_expenditures: [],
    },
    error: null,
  },
  transactions: {
    status: 'idle',
    data: [],
    meta: { total_pages: 1 },
    error: null,
  },
  keyFiguresSummary: {
    status: 'idle',
    data: {},
    error: null,
  },
  bankAccounts: {
    status: 'idle',
    data: [],
    error: null,
  }
}

export const fetchBankAccountsAsync = createAsyncThunk(
  'dashboard/fetchBankAccounts',
  async () => {
    const response = await fetchBankAccounts()
    return response.data
  }
)

export const fetchFinancialOverviewAsync = createAsyncThunk(
  'dashboard/fetchFinancialOverviewAsync',
  async () => {
    const response = await fetchFinancialOverview()
    return response.data
  }
)

export const fetchTransactionsAsync = createAsyncThunk(
  'dashboard/fetchTransactions',
  async (params) => {
    const response = await fetchTransactions(
      params.accountNumber,
      params.page,
      params.missingAttachment
    )
    return response.data
  }
)

export const fetchKeyFiguresSummaryAsync = createAsyncThunk(
  'dashboard/fetchKeyFiguresSummary',
  async () => {
    const response = await fetchKeyFiguresSummary(true, true)
    return response.data
  }
)

export const removeAttachmentAsync = createAsyncThunk(
  'dashboard/removeAttachment',
  async (params) => {
    const response = await removeAttachment(
      params.voucherId,
      params.attachmentId
    )
    return response.data
  }
)

export const fetchIncomeVsExpenditureAsync = createAsyncThunk(
  'dashboard/fetchIncomeVsExpenditureAsync',
  async (params) => {
    const response = await fetchIncomeVsExpenditure(params.from, params.to)
    return response.data
  }
)

export const fetchResultAsync = createAsyncThunk(
  'dashboard/fetchResultAsync',
  async (params) => {
    const response = await fetchResult(params.from, params.to)
    return response.data
  }
)

export const fetchHighestExpendituresAsync = createAsyncThunk(
  'dashboard/fetchHighestExpendituresAsync',
  async (params) => {
    const response = await fetchHighestExpenditures(params.from, params.to)
    return response.data
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: intialDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccountsAsync.pending, (state) => {
        state.bankAccounts.status = 'loading'
      })
      .addCase(fetchBankAccountsAsync.fulfilled, (state, action) => {
        state.bankAccounts.status = 'succeeded'
        state.bankAccounts.data = action.payload
      })
      .addCase(fetchBankAccountsAsync.rejected, (state, action) => {
        state.bankAccounts.status = 'failed'
        state.bankAccounts.error = action.error.message
      })
      .addCase(fetchFinancialOverviewAsync.pending, (state) => {
        state.economy.status = 'loading'
      })
      .addCase(fetchFinancialOverviewAsync.fulfilled, (state, action) => {
        state.economy.status = 'succeeded'
        state.economy.data = action.payload
      })
      .addCase(fetchFinancialOverviewAsync.rejected, (state, action) => {
        state.economy.status = 'failed'
        state.economy.error = action.error.message
      })
      .addCase(fetchTransactionsAsync.pending, (state) => {
        state.transactions.status = 'loading'
      })
      .addCase(fetchTransactionsAsync.fulfilled, (state, action) => {
        state.transactions.data = action.payload.records
        state.transactions.meta = action.payload.meta
        state.transactions.status = 'succeeded'
      })
      .addCase(fetchTransactionsAsync.rejected, (state, action) => {
        state.transactions.status = 'failed'
        state.transactions.error = action.error.message
      })
      .addCase(fetchKeyFiguresSummaryAsync.pending, (state) => {
        state.keyFiguresSummary.status = 'loading'
      })
      .addCase(fetchKeyFiguresSummaryAsync.fulfilled, (state, action) => {
        state.keyFiguresSummary.status = 'succeeded'
        state.keyFiguresSummary.data = action.payload
      })
      .addCase(fetchKeyFiguresSummaryAsync.rejected, (state, action) => {
        state.keyFiguresSummary.status = 'failed'
        state.keyFiguresSummary.error = action.error.message
      })
      .addCase(removeAttachmentAsync.fulfilled, (state, action) => {
        const transactions = [...state.transactions.data]
        const updatedTransactions = transactions.map((transaction) => {
          const rows = [...transaction.rows]
          const index = rows.findIndex(row => row.id === action.payload.id)
          if (index !== -1) {
            rows[index].attachments = action.payload.attachments
            rows[index].need_attachment = rows[index].attachments.length === 0
          }
          transaction.rows = rows
          return transaction
        })
        state.transactions.data = updatedTransactions
        state.transactions.status = 'succeeded'
      })
      .addCase(removeAttachmentAsync.rejected, (state, action) => {
        state.transactions.status = 'failed'
        state.transactions.error = action.error.message
      })
      .addCase(fetchIncomeVsExpenditureAsync.pending, (state) => {
        state.economy.status = 'loading'
      })
      .addCase(fetchIncomeVsExpenditureAsync.fulfilled, (state, action) => {
        state.economy.status = 'succeeded'
        state.economy.data.income = action.payload.income
        state.economy.data.expenditure = action.payload.expenditure
      })
      .addCase(fetchIncomeVsExpenditureAsync.rejected, (state, action) => {
        state.economy.status = 'failed'
        state.economy.error = action.error.message
      })
      .addCase(fetchResultAsync.pending, (state) => {
        state.economy.status = 'loading'
      })
      .addCase(fetchResultAsync.fulfilled, (state, action) => {
        state.economy.status = 'succeeded'
        state.economy.data.result = action.payload
      })
      .addCase(fetchResultAsync.rejected, (state, action) => {
        state.economy.status = 'failed'
        state.economy.error = action.error.message
      })
      .addCase(fetchHighestExpendituresAsync.pending, (state) => {
        state.economy.status = 'loading'
      })
      .addCase(fetchHighestExpendituresAsync.fulfilled, (state, action) => {
        state.economy.status = 'succeeded'
        state.economy.data.highest_expenditures = action.payload
      })
      .addCase(fetchHighestExpendituresAsync.rejected, (state, action) => {
        state.economy.status = 'failed'
        state.economy.error = action.error.message
      })
  },
})

export const selectBankAccountsData = (state) => state.dashboard.bankAccounts.data;
export const selectBankAccountsStatus = (state) => state.dashboard.bankAccounts.status;

export const selectEconomyData = (state) => state.dashboard.economy.data;
export const selectEconomyStatus = (state) => state.dashboard.economy.status;
export const selectEconomyError = (state) => state.dashboard.economy.error;

export const selectTransactions = (state) => state.dashboard.transactions;
export const selectTransactionsStatus = (state) => state.dashboard.transactions.status;
export const selectTransactionsError = (state) => state.dashboard.transactions.error;

export const selectKeyFiguresSummaryData = (state) => state.dashboard.keyFiguresSummary.data;
export const selectKeyFiguresSummaryStatus = (state) => state.dashboard.keyFiguresSummary.status;
export const selectKeyFiguresSummaryError = (state) => state.dashboard.keyFiguresSummary.error;

export default dashboardSlice.reducer;
