import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import Select from 'react-select'
import dayjs from 'dayjs'
import HeaderStats from "../../components/Headers/HeaderStats.js"
import CardLatestTransactions from "../../components/Cards/CardLatestTransactions"
import BankAuthenticationComponent from './components/BankAuthenticationComponent'
import KycComponent from './components/KycComponent'
import ResultChartComponent from './components/ResultChartComponent'
import ComparisonChartComponent from './components/ComparisonChartComponent'
import HighestExpenditureChartComponent from './components/HighestExpenditureChartComponent'
import {
  selectBankAccountsData,
  selectBankAccountsStatus,
  fetchBankAccountsAsync,
  selectEconomyData,
  selectEconomyStatus,
  fetchFinancialOverviewAsync,
  fetchIncomeVsExpenditureAsync,
  fetchHighestExpendituresAsync,
  selectTransactions,
  selectTransactionsStatus,
  fetchTransactionsAsync,
  selectKeyFiguresSummaryData,
  selectKeyFiguresSummaryStatus,
  fetchKeyFiguresSummaryAsync,
  removeAttachmentAsync,
  fetchResultAsync,
} from './dashboardSlice'
import { selectActiveFinancialYear } from '../financialYears/activeFinancialYearIdReducer'
import {
  presentFinancialYearWithMonth,
} from '../../utils/financialYears'

export default function Dashboard() {
  const dispatch = useDispatch()

  const bankAccountsData = useSelector(selectBankAccountsData)
  const bankAccountsStatus = useSelector(selectBankAccountsStatus)
  const economyData = useSelector(selectEconomyData)
  const economyStatus = useSelector(selectEconomyStatus)
  const transactions = useSelector(selectTransactions)
  const transactionsStatus = useSelector(selectTransactionsStatus)
  const keyFiguresSummaryData = useSelector(selectKeyFiguresSummaryData)
  const keyFiguresSummaryStatus = useSelector(selectKeyFiguresSummaryStatus)
  const activeFinancialYear = useSelector(selectActiveFinancialYear)
  const [filterOnMissingAttachment, setFilterOnMissingAttachment] = useState(false)
  const [bankAccountOptions, setBankAccountOptions] = useState([])
  const [accountNumber, setAccountNumber] = useState({})
  const [transactionPage, setTransactionPage] = useState(1)
  const [defaultChartTypeOption, setDefaultChartTypeOption] = useState({})
  const [chartTypeOptions, setChartTypeOptions] = useState([])
  const [incomeVsExpenditureChartType, setIncomeVsExpenditureChartType] = useState(defaultChartTypeOption)
  const [resultChartType, setResultChartType] = useState(defaultChartTypeOption)
  const [highestExpendituresChartType, setHighestExpendituresChartType] = useState(defaultChartTypeOption)

  useEffect(() => {
    if (bankAccountsStatus === 'idle') {
      dispatch(fetchBankAccountsAsync())
    }
  }, [bankAccountsStatus, dispatch])

  useEffect(() => {
    const option = {
      label: `Valt räkenskapsår (${presentFinancialYearWithMonth(activeFinancialYear)})`,
      value: 'financialYear'
    }
    setDefaultChartTypeOption(option)
    setIncomeVsExpenditureChartType(option)
    setResultChartType(option)
    setHighestExpendituresChartType(option)
    setChartTypeOptions([option, {
      label: 'Senaste 12 månaderna',
      value: 'lastTwelveMonths'
    }])
  }, [activeFinancialYear])

  useEffect(() => {
    if (bankAccountsStatus === 'succeeded') {
      const accounts = bankAccountsData.map((account) => {
        return {
          label: account.description,
          value: account.number,
        }
      }).sort((x, y) => {
        if (x.label < y.label) {
          return -1
        }
        if (x.label > y.label) {
          return 1
        }
        if (x.value === 1930 || y.value === 1930) {
          return -1
        }
        return 0
      })
      setBankAccountOptions(accounts)
      const defaultAccount = bankAccountsData.find(acc => acc.number === 1930)
      if (defaultAccount) {
        setAccountNumber({
          label: defaultAccount.description,
          value: defaultAccount.number
        })
      }
    }
  }, [bankAccountsData, bankAccountsStatus, dispatch])

  useEffect(() => {
    if (economyStatus === 'idle') {
      dispatch(fetchFinancialOverviewAsync())
    }
  }, [economyStatus, dispatch])

  useEffect(() => {
    if (transactionsStatus === 'idle' && accountNumber.value !== undefined) {
      dispatch(fetchTransactionsAsync({
        accountNumber: accountNumber.value,
        page: 1,
        missingAttachment: false
      }))
    }
  }, [transactionsStatus, accountNumber, bankAccountsStatus, dispatch])

  useEffect(() => {
    if (keyFiguresSummaryStatus === 'idle') {
      dispatch(fetchKeyFiguresSummaryAsync())
    }
  }, [keyFiguresSummaryStatus, dispatch])

  const fetchTransactions = async(accountNumber, page) => {
    setAccountNumber(accountNumber)
    setTransactionPage(page)
    dispatch(fetchTransactionsAsync({
      accountNumber: accountNumber.value,
      page: page,
      missingAttachment: false
    }))
  }

  const toggleMissingAttachmentFilter = async(missingAttachment) => {
    setFilterOnMissingAttachment(missingAttachment)

    dispatch(fetchTransactionsAsync({
      accountNumber: accountNumber.value,
      page: transactionPage,
      missingAttachment: missingAttachment ? true : false
    }))
  }

  const removeAttachment = (voucherId, attachmentId) => {
    dispatch(removeAttachmentAsync({voucherId: voucherId, attachmentId: attachmentId}))
  }

  const selectIncomeVsExpenditureChartType = (option) => {
    setIncomeVsExpenditureChartType(option)

    if (option.value === 'lastTwelveMonths') {
      const to = dayjs()
      const from = to.subtract(11, "months")
      dispatch(fetchIncomeVsExpenditureAsync({from: from, to: to}))
    } else {
      dispatch(fetchIncomeVsExpenditureAsync({}))
    }
  }

  const selectResultChartType = (option) => {
    setResultChartType(option)

    if (option.value === 'lastTwelveMonths') {
      const to = dayjs()
      const from = to.subtract(11, "months")
      dispatch(fetchResultAsync({from: from, to: to}))
    } else {
      dispatch(fetchResultAsync({}))
    }
  }

  const selectHighestExpendituresChartType = (option) => {
    setHighestExpendituresChartType(option)

    if (option.value === 'lastTwelveMonths') {
      const to = dayjs()
      const from = to.subtract(11, "months")
      dispatch(fetchHighestExpendituresAsync({from: from, to: to}))
    } else {
      dispatch(fetchHighestExpendituresAsync({}))
    }
  }

  return (
    <>
      <HeaderStats data={keyFiguresSummaryData}/>
      <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 mb-12 xl:mb-0 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-slate-50 w-full shadow-lg rounded-t">
              <div className="block w-full text-left p-4">
                <FormControl style={{width: '50%'}}>
                  <Select
                    value={accountNumber}
                    options={bankAccountOptions}
                    isSearchable={false}
                    onChange={(value) => fetchTransactions(value, 1)}
                    styles={{
                      control: provided => ({
                        ...provided,
                        cursor: 'pointer',
                        border: '1px solid black',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid black',
                        }
                      }),
                      menu: provided => ({
                        ...provided,
                        width: '350px',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        cursor: 'pointer',
                        backgroundColor: state.isSelected ? "#ccc" : "transparent",
                        '&:active': {
                           backgroundColor: 'transparent'
                        }
                      }),
                    }}
                  />
                </FormControl>
                {accountNumber.value !== 1630 &&
                  <>
                    <Checkbox onClick={() => toggleMissingAttachmentFilter(!filterOnMissingAttachment)}/>
                    Visa endast saknade underlag
                  </>
                }
              </div>
            </div>
            <CardLatestTransactions
              transactions={transactions}
              loading={transactionsStatus === 'loading'}
              fetchTransactions={fetchTransactions}
              transactionType={accountNumber}
              transactionPage={transactionPage}
              removeAttachment={removeAttachment}
            />
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-slate-700 w-full shadow-lg rounded-t">
              <Grid container>
                <Grid item xs={6}>
                  <div className="block w-full text-left p-2">
                    <h4 style={{color: '#94a3b8', fontSize: '12px', fontWeight: 'bold'}}>ÖVERBLICK</h4>
                    <h1 style={{color: '#fff', fontSize: '20px', fontWeight: 'bold'}}>Intäkter/Utgifter</h1>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="block w-full text-left p-4">
                    <FormControl>
                      <Select
                        value={incomeVsExpenditureChartType}
                        options={chartTypeOptions}
                        isSearchable={false}
                        onChange={(value) => selectIncomeVsExpenditureChartType(value)}
                        styles={{
                          control: provided => ({
                            ...provided,
                            cursor: 'pointer',
                            fontSize: '12px',
                            border: 0,
                            boxShadow: 'none',
                            '&:hover': {
                               border: 0
                            },
                            '&:active': {
                               border: 0
                            }
                          }),
                          menu: provided => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            fontSize: '12px',
                            backgroundColor: state.isSelected ? "#bbb" : "transparent",
                            '&:active': {
                               backgroundColor: 'transparent'
                            }
                          }),
                        }}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>
            <ComparisonChartComponent
              data={{
                expenditure: economyData.expenditure,
                income: economyData.income,
              }}
            />
            <div className="relative flex flex-col min-w-0 break-words bg-slate-700 w-full shadow-lg rounded-t">
              <Grid container>
                <Grid item xs={6}>
                  <div className="block w-full text-left p-2">
                    <h4 style={{color: '#94a3b8', fontSize: '12px', fontWeight: 'bold'}}>ÖVERBLICK</h4>
                    <h1 style={{color: '#fff', fontSize: '20px', fontWeight: 'bold'}}>Ackumulerat resultat</h1>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="block w-full text-left p-4">
                    <FormControl>
                      <Select
                        value={resultChartType}
                        options={chartTypeOptions}
                        isSearchable={false}
                        onChange={(value) => selectResultChartType(value)}
                        styles={{
                          control: provided => ({
                            ...provided,
                            cursor: 'pointer',
                            fontSize: '12px',
                            border: 0,
                            boxShadow: 'none',
                            '&:hover': {
                               border: 0
                            },
                            '&:active': {
                               border: 0
                            }
                          }),
                          menu: provided => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            fontSize: '12px',
                            backgroundColor: state.isSelected ? "#bbb" : "transparent",
                            '&:active': {
                               backgroundColor: 'transparent'
                            }
                          }),
                        }}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>
            <ResultChartComponent
              currency={economyData.result[0] ? economyData.result[0].currency : 'SEK'}
              data={economyData.result}
              label={economyData.result[0] ? economyData.result[0].date.split('-')[0] : 'SEK'}
            />
            <div className="relative flex flex-col min-w-0 break-words bg-slate-700 w-full shadow-lg rounded-t">
              <Grid container>
                <Grid item xs={6}>
                  <div className="block w-full text-left p-2">
                    <h4 style={{color: '#94a3b8', fontSize: '12px', fontWeight: 'bold'}}>ÖVERBLICK</h4>
                    <h1 style={{color: '#fff', fontSize: '20px', fontWeight: 'bold'}}>Högsta utgifter</h1>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="block w-full text-left p-4">
                    <FormControl>
                      <Select
                        value={highestExpendituresChartType}
                        options={chartTypeOptions}
                        isSearchable={false}
                        onChange={(value) => selectHighestExpendituresChartType(value)}
                        styles={{
                          control: provided => ({
                            ...provided,
                            cursor: 'pointer',
                            fontSize: '12px',
                            border: 0,
                            boxShadow: 'none',
                            '&:hover': {
                               border: 0
                            },
                            '&:active': {
                               border: 0
                            }
                          }),
                          menu: provided => ({
                            ...provided,
                            fontSize: '12px'
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            cursor: 'pointer',
                            fontSize: '12px',
                            backgroundColor: state.isSelected ? "#bbb" : "transparent",
                            '&:active': {
                               backgroundColor: 'transparent'
                            }
                          }),
                        }}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </div>
            <HighestExpenditureChartComponent
              expenditures={economyData.highest_expenditures}
            />
          </div>
        </div>
      </div>
      <BankAuthenticationComponent />
      <KycComponent />
    </>
  )
}
