import React, { useState } from 'react'
import i18n from '../i18n'
import Modal from '@mui/material/Modal'
import ClearIcon from '@mui/icons-material/Clear'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import './fileUploader.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  boxShadow: 24,
  p: 4,
};

export default function ImageUploader(props) {
  const { onClose, onUpload, open, title } = props;
  const [file, setFile] = useState(null)

  const handleSubmit = async() =>  {
    onUpload(file)
    setFile(null)
  }

  const renderImage = (file) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const imgPreview = document.getElementById(file.name);
      if (imgPreview) {
        imgPreview.src = fileReader.result;
      }
    };

    fileReader.readAsDataURL(file);

    if (!file) {
      return null
    }

    return (
      <img
        id={file.name}
        alt="attachment"
        width="auto"
      />
    )
  }

  const renderFilePreview = (file, contentType) => {
    const t = i18n.getFixedT();

    if (file.error) {
      return (
        <div className="file-validation-error">
          <p>{`${file.error.fileName}: ${t(file.error.messageKey)}`}</p>
        </div>
      )
    }

    return renderImage(file)
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{margin: '5px 0 15px 0'}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <span className="modal-title">{title || 'Ladda upp'}</span>
              <ClearIcon
                onClick={onClose}
                style={{
                  float: 'right',
                  cursor: 'pointer'
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={2.5} style={{margin: '5px 0 15px 0'}}>
            <button
              variant="outlined"
              className="bg-emerald-500 text-white p-4 rounded"
            >
              <input
                onChange={(event) => setFile(event.target.files[0])}
                type="file"
                id="file-upload"
                accept="image/png,image/jpeg"
                hidden
                multiple={false}
                style={{cursor: 'pointer'}}
              />
              <label htmlFor="file-upload" className="choose-file-label" style={{cursor: 'pointer'}}>
                Välj bild
              </label>
            </button>
          </Grid>
          { file &&
            <>
              <Grid item xs={12} style={{height: 'auto'}}>
                { renderFilePreview(file, file.contentType) }
              </Grid>

              <Grid item xs={12} style={{position: 'absolut', bottom: '10px', left: '10px'}}>
                <button
                  className="bg-emerald-500 text-white p-4 rounded"
                  onClick={handleSubmit}
                  variant="outlined"
                >
                  Spara
                </button>
                <button
                  className="bg-red-500 text-white p-4 rounded ml-2"
                  onClick={() => setFile(null)}
                  variant="outlined"
                >
                  Ta bort
                </button>
              </Grid>
            </>
          }
        </Grid>
      </Box>
    </Modal>
  )

}

