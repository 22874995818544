import React from 'react'
import CardLineChart from '../../../components/Cards/CardLineChart'
import { MONTHS } from '../../../utils/months'
import dayjs from 'dayjs'
import { extractLabels, normalizeDataset } from '../utils'

const ResultChartComponent = (props) => {
  const { data, currency, label } = props
  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#ffffff',
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      tooltip: {
        callbacks: {
          title: ([context]) => {
            return context.raw.tooltip
          },
          label: (context) => {
            return `${context.formattedValue} ${context.dataset.currency}`
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target
      target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    }
  }

  const labels = (data || []).map(item => item.date)
  const result = normalizeDataset(labels, data)
  const chartData = {
    labels: labels.map(label => dayjs(`${label}-01`).format('MMM')),
    datasets: [
      {
        currency: currency,
        label: label,
        data: result,
        backgroundColor: "#4c51bf",
        borderColor: "#4c51bf",
        radius: 4,
        hoverRadius: 7,
        tension: 0.4,
      },
    ],
  }

  return (
    <CardLineChart options={chartOptions} data={chartData} />
  )
}

export default ResultChartComponent
