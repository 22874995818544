import dayjs from 'dayjs'

export const extractLabels = (data) => {
  const labels = data.map(item => item.date)
  return [...new Set(labels)].sort((a, b) => new Date(`${a}-01`) - new Date(`${b}-01`))
}

export const normalizeDataset = (labels, data) => {
  if (!data) {
    return []
  }

  return labels.map((label) => {
    const item = data.find(row => row.date === label)
    if (item) {
      return {
        tooltip: dayjs(`${item.date}-01`).format('MMMM YYYY'),
        x: dayjs(`${item.date}-01`).format('MMM'),
        y: item.amount
      }
    } else {
      return {
        tooltip: dayjs(`${label}-01`).format('MMMM YYYY'),
        x: dayjs(`${label}-01`).format('MMM'),
        y: 0
      }
    }
  })
}
