import React from "react";
import { createPopper } from "@popperjs/core";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const InvoiceActionsDropdown = (props) => {
  const {
    invoice,
    editInvoice,
    copyInvoice,
    creditInvoice,
    printInvoice,
    cancelInvoice,
    openEmailModal
  } = props;

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);

    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
    <ClickAwayListener onClickAway={() => setDropdownPopoverShow(false)}>
      <a
        href="#"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
        style={{cursor: 'pointer'}}
      >
        {/*<i className="fas fa-ellipsis-v"></i>*/}

        <MoreHorizIcon sx={{cursor: 'pointer'}}/>
      </a>
    </ClickAwayListener>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={openEmailModal}
          style={{cursor: 'pointer'}}
        >
          Skicka
        </a>
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={() => printInvoice(invoice)}
          style={{cursor: 'pointer'}}
        >
          Skriv ut
        </a>
        {!invoice.booked &&
          <a
            href="#"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={() => editInvoice(invoice)}
            style={{cursor: 'pointer'}}
          >
            Redigera
          </a>
        }
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={() => copyInvoice(invoice)}
          style={{cursor: 'pointer'}}
        >
          Kopiera
        </a>
        {!invoice.credit &&
          <a
            href="#"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={() => creditInvoice(invoice)}
            style={{cursor: 'pointer'}}
          >
            Kreditera
          </a>
        }
        {!invoice.booked &&
          <a
            href="#"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={(e) => cancelInvoice(invoice)}
            style={{cursor: 'pointer'}}
          >
            Ta bort
          </a>
        }
      </div>
    </>
  );
};

export default InvoiceActionsDropdown;
