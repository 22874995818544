import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export default function CardBarChart(props) {
  const defaultOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "rgba(255,255,255,.7)",
        },
        display: true,
        title: {
          display: false,
          text: "Month",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "rgba(255,255,255,.7)",
        },
        display: true,
        title: {
          display: false,
          text: "Value",
        },
        grid: {
          tickBorderDash: [2],
          drawOnChartArea: true,
          tickBorderDashOffset: 2,
          color: "rgba(255, 255, 255, 0.15)",
          borderDash: [6, 3],
        },
      },
    },
  }

  const config = {
    type: "bar",
    data: props.data,
    options: {...defaultOptions, ...props.options}
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-b bg-slate-700">
      {props.title && props.subTitle &&
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center text-left">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-slate-400 mb-1 text-xs font-semibold">
                {props.title}
              </h6>
              <h2 className="text-white text-xl font-semibold">
                {props.subTitle}
              </h2>
            </div>
          </div>
        </div>
      }
      <div className="p-4 flex-auto">
        <div className="relative h-350-px">
          <Bar
            options={config.options}
            data={config.data}
          />
        </div>
      </div>
    </div>
  )
}
