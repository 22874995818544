import React from "react"
import { createPopper } from "@popperjs/core"
import ClickAwayListener from '@mui/base/ClickAwayListener'
import SettingsIcon from '@mui/icons-material/Settings'

const SettingsDropdown = (props) => {
  const {
    logoUrl,
    openLogoUpload,
    openEditBackgroundColor,
    openEditTextColor,
    removeLogo,
  } = props

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)
  const btnDropdownRef = React.createRef()
  const popoverDropdownRef = React.createRef()

  const openDropdownPopover = () => {
    setDropdownPopoverShow(true)

    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "right-start",
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-6, 10],
          },
        },
      ],
    })
  }

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }

  const onOpenLogoUpload = () => {
    setDropdownPopoverShow(false)
    openLogoUpload()
  }

  return (
    <>
    <ClickAwayListener onClickAway={() => setDropdownPopoverShow(false)}>
      <a
        href="#"
        ref={btnDropdownRef}
      >
        <button
          ref={btnDropdownRef}
          onClick={(e) => {
            e.preventDefault()
            dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
          }}
          className="bg-emerald-500 text-white py-2 px-2 cursor-pointer"
          style={{fontSize: '14px'}}
        >
          Inställningar
        </button>
      </a>
    </ClickAwayListener>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left shadow-lg min-w-48"
        }
      >
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={onOpenLogoUpload}
          style={{cursor: 'pointer'}}
        >
          Ladda upp logo
        </a>
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={() => openEditBackgroundColor()}
          style={{cursor: 'pointer'}}
        >
          Ändra bakgrundsfärg
        </a>
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
          onClick={() => openEditTextColor()}
          style={{cursor: 'pointer'}}
        >
          Ändra textfärg
        </a>
        {logoUrl &&
          <a
            href="#"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            }
            onClick={removeLogo}
            style={{cursor: 'pointer'}}
          >
            Ta bort logo
          </a>
        }
      </div>
    </>
  )
}

export default SettingsDropdown
