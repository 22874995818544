import React from 'react'
import CardBarChart from '../../../components/Cards/CardBarChart'
import { MONTHS } from '../../../utils/months'
import dayjs from 'dayjs'
import { normalizeDataset, extractLabels } from '../utils'

const ExpenditureChartComponent = (props) => {
  const { data } = props
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: "#fff",
        },
        align: "end",
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          title: ([context]) => {
            return context.raw.tooltip
          },
          label: (context) => {
            return `${context.formattedValue} ${context.dataset.currency}`
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target
      target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    }
  }

  const labels = extractLabels((data.income || []).concat(data.expenditure || []))
  const chartData = {
    labels: labels.map(date => dayjs(`${date}-01`).format('MMM')),
    datasets: [
      {
        currency: 'SEK',
        label: 'Intäkter',
        data: normalizeDataset(labels, data.income),
        backgroundColor: "#10b981",
        borderColor: "#10b981",
        fill: false,
        barThickness: 8,
      },
      {
        currency: 'SEK',
        label: 'Utgifter',
        data: normalizeDataset(labels, data.expenditure),
        backgroundColor: "#fff",
        borderColor: "#fff",
        fill: false,
        barThickness: 8,
      },
    ],
  }

  return (
    <CardBarChart options={chartOptions} data={chartData} />
  )
}

export default ExpenditureChartComponent

